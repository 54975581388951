import revive_payload_client_7P5OnNomBl from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_yd7mdg2ercq6mzpgzfnms3h7zu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wx9kasoJH1 from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_yd7mdg2ercq6mzpgzfnms3h7zu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7Ri3Ze5ZYt from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_yd7mdg2ercq6mzpgzfnms3h7zu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vdarZi0uZN from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt-site-config@2.2.21/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_m02POVZpFR from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_yd7mdg2ercq6mzpgzfnms3h7zu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_30i98FrSH6 from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_yd7mdg2ercq6mzpgzfnms3h7zu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_F008InHaGP from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_yd7mdg2ercq6mzpgzfnms3h7zu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_JVflKu2GUL from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_yd7mdg2ercq6mzpgzfnms3h7zu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OzvU7JfvZp from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.8.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ott-web-portal2/ott-web-portal2/.nuxt/components.plugin.mjs";
import prefetch_client_Z6mYROYysC from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_yd7mdg2ercq6mzpgzfnms3h7zu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WQMXwyIzLG from "/home/runner/work/ott-web-portal2/ott-web-portal2/modules/mercadopago/runtime/plugin.ts";
import plugin_z1txnrVC55 from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt-bugsnag@5.9.2/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import motion_vfcqzW6B3g from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@vueuse+motion@2.2.6/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import switch_locale_path_ssr_z331SG4VGO from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@nuxtjs+i18n@8.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_e3yh1ImzT3 from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@nuxtjs+i18n@8.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_eNelkHx7DD from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import auto_animate_client_vQNOeunMWK from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/auto-animate.client.ts";
import floating_vue_BiOD74u9sH from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/floating-vue.ts";
import google_analytics_client_KruZkwwzId from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/google-analytics.client.ts";
import modals_RaVbrZQ2Fe from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/modals.ts";
import one_signal_client_jB01tVDMgQ from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/one-signal.client.ts";
import persistedstate_client_O5MsxXlNtZ from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/persistedstate.client.ts";
import polyfills_client_xo6BY28vLt from "/home/runner/work/ott-web-portal2/ott-web-portal2/plugins/polyfills.client.ts";
export default [
  revive_payload_client_7P5OnNomBl,
  unhead_wx9kasoJH1,
  router_7Ri3Ze5ZYt,
  _0_siteConfig_vdarZi0uZN,
  payload_client_m02POVZpFR,
  navigation_repaint_client_30i98FrSH6,
  check_outdated_build_client_F008InHaGP,
  chunk_reload_client_JVflKu2GUL,
  plugin_vue3_OzvU7JfvZp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Z6mYROYysC,
  plugin_WQMXwyIzLG,
  plugin_z1txnrVC55,
  motion_vfcqzW6B3g,
  switch_locale_path_ssr_z331SG4VGO,
  i18n_e3yh1ImzT3,
  plugin_client_eNelkHx7DD,
  auto_animate_client_vQNOeunMWK,
  floating_vue_BiOD74u9sH,
  google_analytics_client_KruZkwwzId,
  modals_RaVbrZQ2Fe,
  one_signal_client_jB01tVDMgQ,
  persistedstate_client_O5MsxXlNtZ,
  polyfills_client_xo6BY28vLt
]