import { AnalyticsBrowser } from "@segment/analytics-next";
import { onMounted, ref } from "vue";
import type { SEGMENT_TRACK_EVENTS, SHARE_SOCIAL_LINK, SOURCE_LOCATION } from "~/constants";

export type TSocialMediaPlatform = typeof SHARE_SOCIAL_LINK[keyof typeof SHARE_SOCIAL_LINK];
interface IIdentifyAuth {
  email: string;
  name: string;
  country: string;
  tenant: string;
  last_sign_method: TAuthProvider;
}

type TSourceLocation = typeof SOURCE_LOCATION[keyof typeof SOURCE_LOCATION];

export interface ISegmentTracks {
  [SEGMENT_TRACK_EVENTS.SIGN_UP_STARTED]: any;
  [SEGMENT_TRACK_EVENTS.KYC_SKIPPED]: any;
  [SEGMENT_TRACK_EVENTS.KYC_COMPLETED]: any;
  [SEGMENT_TRACK_EVENTS.SIGN_UP_COMPLETED]: {
    sign_method: TAuthProvider;
  };
  [SEGMENT_TRACK_EVENTS.USER_SUCCESSFUL_LOGIN]: {
    sign_method: TAuthProvider;
  };
  [SEGMENT_TRACK_EVENTS.USER_FAILED_LOGIN]: {
    sign_method: TAuthProvider;
    reason: { error_code: string; message: string };
  };
  [SEGMENT_TRACK_EVENTS.TAG_CREATED]: {
    event_id: string;
    video_type: "vod" | "live";
    type: TTagType;
  };
  [SEGMENT_TRACK_EVENTS.HIGHLIGHT_PAGE_VISITED]: any;
  [SEGMENT_TRACK_EVENTS.VISIT_FOLLOWING_PAGE]: any;
  [SEGMENT_TRACK_EVENTS.TEAM_FOLLOWED]: {
    team_id: string;
    team_name: string;
  };
  [SEGMENT_TRACK_EVENTS.TEAM_UNFOLLOWED]: {
    team_id: string;
    team_name: string;
  };
  [SEGMENT_TRACK_EVENTS.CATEGORY_FOLLOWED]: {
    category_id: string;
    category_name: string;
  };
  [SEGMENT_TRACK_EVENTS.CATEGORY_UNFOLLOWED]: {
    category_id: string;
    category_name: string;
  };
  [SEGMENT_TRACK_EVENTS.CLIPPING_PROCESS_STARTED]: {
    event_id: string;
  };
  [SEGMENT_TRACK_EVENTS.CLIP_CREATED]: {
    event_id: string;
  };
  [SEGMENT_TRACK_EVENTS.CLICK_ON_BANNERS]: {
    url: string;
    source_location: TSourceLocation;
  };
  [SEGMENT_TRACK_EVENTS.VIDEO_CAST_STARTED]: {
    stream_id: string;
    type: "chromecast" | "air_play";
  };
  [SEGMENT_TRACK_EVENTS.SELECT_ROUND]: {
    round_name: string;
    round_id: string;
  };
  [SEGMENT_TRACK_EVENTS.CLICK_ON_LEAGUE]: {
    league_id: string;
    league_name: string;
  };
  [SEGMENT_TRACK_EVENTS.EVENT_SHARE_TO_SOCIAL]: {
    event_id: string;
    method: TSharingProvider;
  };
  [SEGMENT_TRACK_EVENTS.CLIP_SHARE_TO_SOCIAL]: {
    event_id: string;
    clip_id: string;
    method: TSharingProvider;
  };
  [SEGMENT_TRACK_EVENTS.BACK_TO_FULL_EVENT_CLICKED]: {
    event_id: string;
    highlight_id?: string;
    clip_id?: string;
    type: "highlight" | "clip";
  };
  [SEGMENT_TRACK_EVENTS.CLICK_ON_SOCIAL_MEDIA_LINK]: {
    method: TSocialMediaPlatform;
    url: string;
    source_location: TSourceLocation;
  };
  [SEGMENT_TRACK_EVENTS.CLICKS_ON_ROUNDS]: any;
  [SEGMENT_TRACK_EVENTS.DOWNLOAD_CLIP_STARTED]: {
    event_id: string;
    clip_id: string;
  };
}

export interface ISegmentAnalytics {
  page: (path: string, params?: any) => void;
  reset: () => void;
  group: () => void;
  track: <T extends keyof ISegmentTracks>(event: T, params?: ISegmentTracks[T]) => void;
  identify: (userId: string, user: Partial<IIdentifyAuth>) => void;
}

type WarnMethod = "page" | "reset" | "group" | "track" | "identify";

const analytics = ref<ISegmentAnalytics | null>(null);

export default function useSegment(): ISegmentAnalytics {
  const runtimeConfig = useRuntimeConfig().public;
  const segmentApiKey = runtimeConfig.APP_CUSTOMER.app_config.api_key_segment || runtimeConfig.APP_GENERAL.api_key_segment;
  const tenant = runtimeConfig.APP_CUSTOMER.web_sdk.tenant;

  const warn = (method: WarnMethod, args: any[]): void => {
    console.warn(`[Segment]: 'analytics.${method}()' disabled since Segment tracking is not initialized`, { args });
  };

  if (getCurrentInstance()) {
    onMounted(async () => {
      try {
        const [analyticsInstance] = await AnalyticsBrowser.load({ writeKey: segmentApiKey });
        analytics.value = analyticsInstance;
      }
      catch (error) {
        console.error("Failed to load Segment Analytics", error);
      }
    });
  }

  return {
    page: (path, params) => analytics.value?.page(path, { ...params, tenant }) ?? warn("page", [path, params]),
    reset: () => analytics.value?.reset() ?? warn("reset", []),
    group: () => analytics.value?.group() ?? warn("group", []),
    track: (event, params) => analytics.value?.track(event, { ...params, tenant }) ?? warn("track", [event, params]),
    identify: (userId, user) => analytics.value?.identify(userId, user) ?? warn("identify", [userId, user]),
  };
}
